import React from "react";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import { ButtonRow, FormInput } from "@omnigenbiodata/react";
import { MESSAGES } from "../../../../../../../../core/constants/forms.constants";
import { ScanEvent } from "../../../../../../../../components";

interface EditAliquotDialogProps {
  index: number;
  isOpen?: boolean;
  value: string;
  onClose?: () => void;
  onSave?: (value: string, index: number) => void;
  onRemove?: (index: number) => void;
}

function EditAliquotDialog({
  index,
  isOpen = false,
  onClose,
  onSave,
  onRemove,
  value,
}: EditAliquotDialogProps) {
  const validationSchema = yup.lazy(() =>
    yup.object({
      newAliquotId: yup.string().required(MESSAGES.aliquotRequired),
    })
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      newAliquotId: value || "",
      index,
    },
    validationSchema,
    onSubmit: (values) => {
      if (onSave) {
        onSave(values.newAliquotId, index);
      }
    },
  });

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="cryobox-cell-edit-title"
      open={isOpen}
    >
      {index !== undefined && (
        <DialogTitle id="cryobox-cell-edit-title">
          Cryobox Position {index + 1}
        </DialogTitle>
      )}
      <DialogContent>
        <FormikProvider value={formik}>
          <Form>
            <ScanEvent
              onScan={(scanCode: string) => {
                if (scanCode && index !== undefined) {
                  formik.setFieldValue("newAliquotId", scanCode);
                }
              }}
            />
            <Typography variant="body1" component="p">
              Scan a new barcode to replace the current aliquot ID
            </Typography>
            <Box mb={4}>
              <FormInput
                label="New Aliquot ID"
                type="text"
                name={`newAliquotId`}
                error={formik.errors.newAliquotId}
                touched={formik.touched.newAliquotId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newAliquotId}
                readOnly
              />
            </Box>
            <Box mb={2}>
              <ButtonRow
                buttonSize="small"
                forwardLabel="Update"
                onBack={onClose}
                backLabel="Cancel"
                backIcon={null}
                forwardIcon={null}
              />
            </Box>

            {value && value !== "" && (
              <>
                <Box mb={4}>
                  <hr />
                </Box>
                <Box mb={2}>
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    fullWidth
                    type="button"
                    onClick={() => {
                      if (onRemove && index !== undefined) {
                        onRemove(index);
                      }
                    }}
                  >
                    Delete
                  </Button>
                </Box>
              </>
            )}
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}

export default EditAliquotDialog;
