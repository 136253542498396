import React, { useMemo, useState } from "react";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import { ButtonRow, FormStepper } from "@omnigenbiodata/react";
import { Redirect, useHistory } from "react-router-dom";
import InnerLayout from "../../../../../../../../layout/Inner";
import FORMS from "../../../../../../../../core/constants/forms.constants";
import { Panel, ScanEvent } from "../../../../../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../../../../../store";
import {
  samplesSelector,
  sampleTypeSelector,
} from "../../../../../../../../store/batchCreate/selectors";
import ROUTES from "../../../../../../../../core/constants/routes.constants";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { CryoVialList, CryoVialRow } from "./components";
import { batchCreateForward } from "../../../../../../../../store/batchCreate";
import { SampleFormValues } from "../../../../../../../../store/batchCreate/types";
import { getAliquotsSchema } from "../../../../../../../../core/validation/aliquots.validation";

function CryoVialsScene() {
  const sampleType = useAppSelector(sampleTypeSelector);
  const samples = useAppSelector(samplesSelector);
  const [scanned, setScanned] = useState<string[]>([]);
  const [scannedRows, setScannedRows] = useState<string[][]>([]);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      samples:
        (samples?.map((sample, index) => {
          return {
            ...sample,
            aliquot1ID: scannedRows[index] ? scannedRows[index][0] : undefined,
            aliquot2ID: scannedRows[index] ? scannedRows[index][1] : undefined,
          };
        }) as SampleFormValues[]) || [],
    },
    initialTouched: {
      samples:
        samples?.map((sample, index) => {
          return {
            aliquot1ID:
              scannedRows[index] && scannedRows[index][0] ? true : false,
            aliquot2ID:
              scannedRows[index] && scannedRows[index][1] ? true : false,
          };
        }) || [],
    },
    validationSchema: getAliquotsSchema(sampleType),
    onSubmit: (values) => {
      dispatch(batchCreateForward(values));
      history.push(ROUTES.batchesCreateAliquotting);
    },
  });

  const scanningPaused = useMemo(() => {
    return (
      formik.values.samples
        .filter((sample, index) => {
          const errors = formik.errors.samples
            ? (formik.errors.samples[index] as any)
            : {};
          const touched = formik.touched.samples
            ? (formik.touched.samples[index] as any)
            : {};

          return (
            (sample.aliquot1ID && errors?.aliquot1ID && touched?.aliquot1ID) ||
            (sample.aliquot2ID && errors?.aliquot2ID && touched?.aliquot2ID)
          );
        }, [])
        .map((sample) => sample.barcode).length > 0
    );
  }, [formik.values, formik.errors, formik.touched]);

  const handleRemoveCryoRow = (rowIndex: number, colIndex: number) => {
    const newScanned = scannedRows.reduce((prev, curr, index) => {
      if (rowIndex === index) {
        if (colIndex === 0 && curr[1]) {
          return [...prev, curr[1]];
        } else if (colIndex === 1 && curr[0]) {
          return [...prev, curr[0]];
        }
        return [...prev];
      }
      return [...prev, curr[0], curr[1]];
    }, []);
    setScanned(newScanned);
    setScannedRows(_.chunk(newScanned, 2));
  };

  const handleScan = (scanCode: string) => {
    if (!scanningPaused) {
      const newScanned = [...scanned, scanCode];
      setScanned(newScanned);
      setScannedRows(_.chunk(newScanned, 2));
    }
  };

  if (!sampleType || !samples) {
    return <Redirect to={ROUTES.batchesCreate} />;
  }

  return (
    <InnerLayout>
      <FormikProvider value={formik}>
        <Form>
          <FieldArray name="samples">
            {() => (
              <>
                <ScanEvent onScan={handleScan} />
                <Typography
                  variant="h4"
                  component="h1"
                  align="center"
                  paragraph
                >
                  Sample Processing
                </Typography>
                <FormStepper steps={FORMS.PROCESSING_STEPS} activeStep={2} />
                <Panel
                  mb={6}
                  title="Aliquot Initial Positions"
                  intro="Please scan the labels on the EMPTY cryovials linked to each primary barcode"
                >
                  {formik.values.samples.length > 0 && (
                    <CryoVialList>
                      {formik.values.samples.map(
                        (sample: SampleFormValues, index: number) => (
                          <CryoVialRow
                            key={`${sample.barcode}-${index}`}
                            index={index}
                            sampleType={sampleType}
                            onDelete={handleRemoveCryoRow}
                          />
                        )
                      )}
                    </CryoVialList>
                  )}
                </Panel>
                <ButtonRow showForward={formik.isValid} buttonSize="small" />
              </>
            )}
          </FieldArray>
        </Form>
      </FormikProvider>
    </InnerLayout>
  );
}

export default CryoVialsScene;
