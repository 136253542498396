import React from "react";
import { RiSearch2Line, RiArrowRightLine } from "react-icons/ri";
import * as yup from "yup";
import { useFormik } from "formik";
import { MESSAGES } from "../../../../../../core/constants/forms.constants";
import { FormInput, FormSelect, SelectOption } from "@omnigenbiodata/react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Panel } from "../../../../../../components";
import { Freezer } from "../../../../../../core/api/lab.types";

interface StorageSearchProps {
  onSearch: (values: object) => void;
  searchTerm?: string;
  freezerId?: string;
  bioBanked?: string;
  buttonLabel?: string;
  freezerList?: Freezer[];
  showTitle?: boolean;
}

function StorageSearch({
  searchTerm = "",
  freezerId = "",
  bioBanked = "false",
  buttonLabel = "Search",
  onSearch,
  freezerList = [],
  showTitle = true,
}: StorageSearchProps) {
  const validationSchema = yup.object({
    searchTerm: yup.string(),
    freezerId: yup.string(),
    bioBanked: yup.string().required(MESSAGES.biobankedRequired),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchTerm,
      freezerId,
      bioBanked,
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      onSearch(values);
    },
  });

  return (
    <Panel mb={2}>
      {showTitle && (
        <Typography component="h2" paragraph variant="h6">
          Cryobox Search
        </Typography>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm>
            <Grid container spacing={2}>
              <Grid item xs={12} sm>
                <FormInput
                  label="Cryobox ID / Aliquot ID"
                  type="text"
                  name={`searchTerm`}
                  error={formik.errors.searchTerm}
                  touched={formik.touched.searchTerm}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.searchTerm}
                  margin="none"
                  startAdornment={<RiSearch2Line />}
                />
              </Grid>
              <Grid item xs={12} sm>
                <FormSelect
                  label="Freezer"
                  name={`freezerId`}
                  error={formik.errors.freezerId}
                  touched={formik.touched.freezerId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Freezer"
                  margin="none"
                  options={
                    freezerList.map((freezer: Freezer) => ({
                      value: freezer.freezerId,
                      label: `${freezer.name} (${freezer.serialNo})`,
                    })) as SelectOption[]
                  }
                  value={formik.values.freezerId}
                />
              </Grid>
              <Grid item xs={12} sm>
                <FormSelect
                  label="Biobank"
                  name={`bioBanked`}
                  error={formik.errors.bioBanked}
                  touched={formik.touched.bioBanked}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Biobank"
                  margin="none"
                  options={[
                    {
                      value: "true",
                      label: `Include Biobank`,
                    },
                    {
                      value: "false",
                      label: `Exclude Biobank`,
                    },
                  ]}
                  value={formik.values.bioBanked}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button
              type="submit"
              variant="outlined"
              size="small"
              color="default"
              fullWidth
            >
              {buttonLabel} <RiArrowRightLine />
            </Button>
          </Grid>
        </Grid>
      </form>
    </Panel>
  );
}

export default StorageSearch;
