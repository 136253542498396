import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { Form, FormikProvider, FieldArray, useFormik } from "formik";
import { ButtonRow, FormStepper } from "@omnigenbiodata/react";
import InnerLayout from "../../../../../../../../layout/Inner";
import { ScanEvent, Panel } from "../../../../../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../../../../../store";
import { batchCreateForward } from "../../../../../../../../store/batchCreate";
import {
  samplesSelector,
  sampleTypeSelector,
} from "../../../../../../../../store/batchCreate/selectors";
import ROUTES from "../../../../../../../../core/constants/routes.constants";
import FORMS from "../../../../../../../../core/constants/forms.constants";

import { Placeholder, BarcodeList, BarcodeRow } from "./components/";
import { SampleFormValues } from "../../../../../../../../store/batchCreate/types";
import { SampleTypeNameEnum } from "../../../../../../../../core/api/lab.types";
import {
  DEFAULT_PRIMARY_VOLUME,
  MAX_PRIMARY_BARCODES,
  DEFAULT_PRIMARY_STATUS,
} from "../../../../../../../../core/constants/lab.constants";
import {
  yupBarcode,
  yupPrimarySamples,
} from "../../../../../../../../core/validation/samples.validation";

function PrimaryTubesScene() {
  const sampleType = useAppSelector(sampleTypeSelector);
  const samples = useAppSelector(samplesSelector);
  const [errors, setErrors] = useState<string[] | undefined>(undefined);
  const [isAdding, setIsAdding] = useState<boolean>(true);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      samples: samples || [],
    },
    validateOnMount: true,
    validationSchema: yupPrimarySamples,
    onSubmit: (values) => {
      dispatch(batchCreateForward(values));
      history.push(ROUTES.batchesCreateCryoVials);
    },
  });

  if (!sampleType) {
    return <Redirect to={ROUTES.batches} />;
  }

  return (
    <InnerLayout>
      <FormikProvider value={formik}>
        <Form>
          <FieldArray name="samples">
            {({ remove, push }) => (
              <>
                <ScanEvent
                  onScan={(scanCode) => {
                    setIsAdding(true);
                    if (formik.values.samples.length < MAX_PRIMARY_BARCODES) {
                      if (
                        formik.values.samples
                          .map((obj: SampleFormValues) => obj.barcode)
                          .includes(scanCode)
                      ) {
                        setErrors([FORMS.MESSAGES.primarySamplesDuplicate]);
                      } else {
                        try {
                          yupBarcode.validateSync(scanCode);
                          push({
                            barcode: scanCode,
                            volume: DEFAULT_PRIMARY_VOLUME,
                            condition: DEFAULT_PRIMARY_STATUS,
                          });
                          setErrors(undefined);
                        } catch (e: any) {
                          console.log(e.message);
                        }
                      }
                    }
                  }}
                />

                <Typography
                  variant="h4"
                  component="h1"
                  align="center"
                  paragraph
                >
                  Sample Processing
                </Typography>
                <FormStepper steps={FORMS.PROCESSING_STEPS} activeStep={1} />
                {sampleType && (
                  <Panel
                    mb={6}
                    title="Primary Tubes"
                    intro={`Please scan the barcode labels of the primary sample tubes for **${SampleTypeNameEnum[sampleType]}** you are aliquotting in the order they are arranged in the tube
          rack (maximum ${MAX_PRIMARY_BARCODES} samples):`}
                  >
                    {errors && (
                      <Box mb={2}>
                        <Alert severity="error">{errors[0]}</Alert>
                      </Box>
                    )}
                    {(!formik.values.samples ||
                      formik.values.samples.length === 0) && <Placeholder />}
                    {formik.values.samples.length > 0 && (
                      <BarcodeList>
                        {formik.values.samples.map(
                          (sample: SampleFormValues, index: number) => (
                            <BarcodeRow
                              index={index}
                              key={`${sample.barcode}-${index}`}
                              onDelete={() => {
                                setErrors(undefined);
                                setIsAdding(false);
                                remove(index);
                              }}
                              isAdding={isAdding}
                              sampleType={sampleType}
                            />
                          )
                        )}
                      </BarcodeList>
                    )}
                  </Panel>
                )}
                <ButtonRow showForward={formik.isValid} buttonSize="small" />
              </>
            )}
          </FieldArray>
        </Form>
      </FormikProvider>
    </InnerLayout>
  );
}

export default PrimaryTubesScene;
